.flex {
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 80px 120px;
    flex-direction: row !important;
    width: fit-content;
    max-width: 100%;
    align-self: center;
}

.outline {
    padding: 6px 12px 5px 12px;
    border: 1px solid hsla(var(--color-alpha), .5);
    transition: border .3s ease-out;
}

.outline > span {
    margin-left: 10px;
    font-weight: 600;
}

.first {
    display: flex;
    flex: 1;
    overflow: visible;
    max-width: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 28px;
    width: fit-content;
}

.first > h2 {
    font-size: 40px;
    white-space: nowrap;
    overflow: visible;
}

.second {
    flex: 1;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 5rem;
}

.second > h1 {
    font-size: clamp(100px, 30vw, 240px);
    letter-spacing: 0;
    font-weight: bold;
}

.second > svg {
    position: absolute;
    animation: scale 10s ease-out forwards;
}

.links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 300px;
    gap: 20px;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    transition: background-color .2s ease-in-out;
  }

@keyframes scale {
    to {scale: 0}
}

@media screen and (max-width: 1000px) {
    .flex {
        flex-direction: column !important;
        align-items: center;
        gap: 30px;
        padding: 40px 20px;
    }
    .second {
        order: 1;
        margin-top: 0;
        padding-top: 1rem;
    }
    .second > h1 {
        line-height: clamp(100px, 30vw, 240px);
    }
    .second > svg {
        height: 240px;
    }
    .first {
        order: 2;
        transform: none;
        align-items: center;
        max-width: none;
        text-align: center;
    }
    .first > h2 {
        white-space: normal;
        font-size: 30px;
    }
    .outline {
        margin: 20px 0px;
    }
}